import Typewriter from "typewriter-effect";
import profilePictureHero from "../../img/profile-picture.jpeg";
import "./HeroProfile.css";

import { useTheme } from "../../context/ThemeContext";

interface HeroProfileprops {}

export const HeroProfile = ({ ...props }: HeroProfileprops) => {
  const { activeTheme } = useTheme();
  return (
    <>
      <div className="hero-profile-container">
        <div className="hero-profile">
          <img src={profilePictureHero} className="hover-img" alt="Jay Patel Profile" />
          <p>Hey there I'm,</p>
          <h1>Jay Patel</h1>
          <Typewriter
            options={{
              strings: [
                "Software Developer",
                "Tech Artisan",
                "Full-Stack Innovator",
                "Dev Maestro",
              ],
              autoStart: true,
              loop: true,
            }}
          />
          <p className="hero-profile-tagline">
            "I excel at designing and developing user-centric software, websites, and applications using modern technologies and frameworks."
          </p>
        </div>
      </div>
    </>
  );
};

export default HeroProfile;
